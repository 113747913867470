import * as ACCOUNT_ACTIONS from 'constants/action_types/accounts/amazonSellerAccounts'
import {
  AMAZON_SELLER_KEYS_SYNC_STEPS,
  PPC_SYNC_STATUS
} from 'constants/account'
import { SET_AMAZON_SELLER_ACCOUNTS_DATA } from 'constants/action_types/amazon_seller_accounts'

export const showAccountSyncModal = modal => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.SELLER_ACCOUNT_SYNC_SHOW_MODAL,
    payload: {
      modal
    }
  })
}

export const closeAccountSyncModal = () => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.SELLER_ACCOUNT_SYNC_CLOSE_MODAL
  })
}

export const showPPCSyncModal = (
  sellerAccountId,
  ppcSyncStatus = PPC_SYNC_STATUS.initial
) => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.SELLER_PPC_ACCOUNT_SYNC_SHOW_MODAL,
    payload: {
      sellerAccountId,
      ppcSyncStatus
    }
  })
}

export const sellerSyncAuthenticationSucceeded = ({
  region,
  primaryMarketplaceId,
  spapiOauthCode,
  sellingPartnerId
}) => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_SELLER_SYNC_SET_DATA,
    payload: {
      merchant_id: sellingPartnerId,
      primaryMarketplaceId,
      region,
      spapi_oauth_code: spapiOauthCode
    }
  })

  dispatch({
    type: ACCOUNT_ACTIONS.SELLER_ACCOUNT_SYNC_SHOW_MODAL,
    payload: {
      modal: AMAZON_SELLER_KEYS_SYNC_STEPS.accountSetup
    }
  })
}

export const sellerSyncAuthenticationFailed = ({
  region,
  primaryMarketplaceId
}) => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_SELLER_SYNC_SET_DATA,
    payload: {
      region,
      primaryMarketplaceId
    }
  })

  dispatch({
    type: ACCOUNT_ACTIONS.SELLER_ACCOUNT_SYNC_SHOW_MODAL,
    payload: {
      modal: AMAZON_SELLER_KEYS_SYNC_STEPS.setupError
    }
  })
}
export const sellerSyncAuthenticationLoading = () => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.SELLER_ACCOUNT_SYNC_SHOW_MODAL,
    payload: {
      modal: AMAZON_SELLER_KEYS_SYNC_STEPS.setupLoading
    }
  })
}

export const setSellerAccountStatusOk = res => dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE,
    payload: res.data
  })
}

export const setSellerAccountStatusState = res => dispatch => {
  dispatch({
    type:
      ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_GET_STATUS_COMPLETE_STATUS,
    payload: {
      success: res.ok
    }
  })
}

export const setAmazonSellerAccountsData = (
  data,
  accountSelector
) => dispatch => {
  dispatch({
    type: SET_AMAZON_SELLER_ACCOUNTS_DATA,
    payload: {
      amazonSellerAccounts: data,
      amazonSellerAccountSelector: accountSelector,
      sellerDataAvailable: true
    }
  })
}

export const setSellerHasPpcAccess = accountId => dispatch => {
  if (accountId) {
    dispatch({
      type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_SET_HAS_PPC_ACCESS,
      payload: accountId
    })
  }
}
