// React Hot Loader needs to be imported before everything
import 'src/js/config/wdyr'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import { HistoryRouter } from 'redux-first-history/rr6'
import { checkLoginRedirection } from 'helpers/redirects'
import { useCategories } from 'hooks/categories'

import i18n from './js/services/i18n' // eslint-disable-line

import App from './js/containers/app_container'

import store, { history } from './js/store'

import { initSentry } from './js/config/sentry'
import { initDatadog } from './js/config/datadog'
import { queryClient } from './js/services/query_client'

import './css/main.sass'
import { ErrorBoundary } from './js/components/common/ErrorBoundary'

const willRedirect = checkLoginRedirection()

// Add hooks that need to be run on initial render
const InitialHooks = () => {
  useCategories()
  return null
}

const init = () => {
  initSentry()
  initDatadog()
  const container = document.getElementById('root')
  const root = createRoot(container)

  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HistoryRouter history={history}>
          <ErrorBoundary>
            <App />
            <InitialHooks />
          </ErrorBoundary>
        </HistoryRouter>
      </QueryClientProvider>
    </Provider>
  )
}

if (!willRedirect) {
  init()
}
